<template>
  <footer class="footer">
    <a
      class="footer__link"
      href="/docs/public_offer.pdf"
      target="_blank"
      rel="noopener noreferrer"
      >Договор-оферта</a
    >
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>

<style lang="scss" scoped>
.footer {
  @include desktopXL {
    padding-bottom: uniWXL(25);
  }
  @include desktop {
    padding-bottom: uniW(25);
  }
  @include mobile {
    padding-bottom: uniWM(25);
  }
  text-align: center;
  &__link {
    @include desktopXL {
      font-size: uniWXL(14);
      margin: 0 uniWXL(10);
    }
    @include desktop {
      font-size: uniW(14);
      margin: 0 uniW(10);
    }
    @include mobile {
      font-size: uniWM(14);
      margin: 0 uniWM(10);
    }
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}
</style>
