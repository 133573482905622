const breakpoints_window = {
  sm: 768,
  lg: 1440,
  xl: 1920,
}

/**
 * Миксин добавляет глобальные переменные:
 * device {String} (mobile, tablet, desktop)
 * breakpoints {String} (xs, sm, md, lg, xl, xxl)
 */
export default {
  computed: {
    device() {
      if (
        this.breakpoints_window === 'sm' ||
        this.windowWidth <= this.windowHeight
      ) {
        return 'mobile'
      } else if (this.breakpoints_window === 'md') {
        return 'desktop'
      }
      return 'desktop-xl'
    },
  },
  data() {
    return {
      breakpoints_window: 'sm',
      windowWidth: 1440,
      windowHeight: 1440,
    }
  },
  created() {
    this.onResizeDevice()
    window.addEventListener('resize', this.onResizeDevice)
  },
  methods: {
    uni(value) {
      const result =
        this.device == 'mobile'
          ? value / (375 / 100)
          : this.device == 'desktop'
          ? value / (1440 / 100)
          : value / (1920 / 100)
      return result + 'vw'
    },
    onResizeDevice() {
      const width = window.innerWidth
      const height = window.innerHeight
      this.windowWidth = width
      this.windowHeight = height

      if (width < breakpoints_window.sm) {
        this.breakpoints_window = 'sm'
      } else if (
        width >= breakpoints_window.sm &&
        width <= breakpoints_window.lg
      ) {
        this.breakpoints_window = 'md'
      } else if (width > breakpoints_window.lg) {
        this.breakpoints_window = 'lg'
      }
    },
  },
}
