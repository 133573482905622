<template>
  <button :class="{ prev }" type="button">
    <img
      :style="{ width: uni(btnWidth) }"
      src="@/assets/icon/swiper-button.svg"
    />
  </button>
</template>

<script>
export default {
  props: {
    prev: {
      type: Boolean,
      default: false,
    },
    btnWidth: {
      type: Number,
      default: 32,
    },
  },
  name: 'ButtonSwiper',
}
</script>

<style lang="scss" scoped>
button {
  z-index: 10;
}
.prev {
  transform: rotate(180deg);
}
</style>
