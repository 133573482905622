<template>
  <div class="view">
    <Swiper
      :space-between="20"
      :slides-per-view="'auto'"
      direction="vertical"
      mousewheel
      :modules="modulesPreviw"
      @swiper="onSwiperPreview"
      class="swiper-preview"
    >
      <SwiperSlide v-for="(item, i) in array" :key="i">
        <div class="white" @click="toSlideView(i + 1)">
          <img :src="require(`@/assets/img/${room}/preview-${i + 1}.webp`)" />
        </div>
      </SwiperSlide>
    </Swiper>
    <div class="swiper-relative">
      <Swiper
        :slides-per-view="1"
        :navigation="{
          nextEl: '.button-next-' + room,
          prevEl: '.button-prev-' + room,
        }"
        :modules="modules"
        :pagination="{
          el: '.pagination-' + room,
          clickable: true,
        }"
        loop
        @swiper="onSwiperView"
        class="swiper-view"
      >
        <SwiperSlide
          v-for="(item, i) in array"
          :key="i"
          @click="
            setFullImagesParams({
              room,
              count,
              initialSlide: i,
              isShow: true,
            })
          "
        >
          <div class="white">
            <img :src="require(`@/assets/img/${room}/img-${i + 1}.webp`)" />
          </div>
        </SwiperSlide>
      </Swiper>
      <div class="navigation-wrapper">
        <ButtonSwiper
          :class="`button-prev-${room} navigation-button`"
          :btn-width="24"
          prev
        />
        <div :class="`pagination-${room} orange pagination`" />
        <ButtonSwiper
          :class="`button-next-${room} navigation-button`"
          :btn-width="24"
        />
      </div>
    </div>
    <slot name="info" />
    <FullView
      v-if="isShow"
      :initialSlide="initialSlide"
      :count="count"
      :room="room"
      @close="isShow = false"
    />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { ref } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'
import 'swiper/swiper.min.css'
import 'swiper/modules/navigation/navigation.scss'
import 'swiper/modules/pagination/pagination.scss'
import { Navigation, Pagination, Mousewheel } from 'swiper'
import ButtonSwiper from './ButtonSwiper.vue'
import FullView from './FullView.vue'
export default {
  props: {
    room: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
  },
  name: 'ApartmentView',
  components: {
    Swiper,
    SwiperSlide,
    ButtonSwiper,
    FullView,
  },
  data() {
    return {
      isShow: false,
      initialSlide: 0,
    }
  },
  computed: {
    array() {
      return new Array(this.count)
    },
  },
  methods: {
    ...mapMutations(['setFullImagesParams']),
  },
  setup() {
    const swiperPreview = ref(null)
    const swiperView = ref(null)

    const onSwiperPreview = (sw) => {
      swiperPreview.value = sw
    }
    const onSwiperView = (sw) => {
      swiperView.value = sw
    }
    const toSlideView = (index) => {
      swiperView.value.slideTo(index)
    }

    return {
      onSwiperPreview,
      onSwiperView,
      toSlideView,
      modules: [Navigation, Pagination],
      modulesPreviw: [Mousewheel],
    }
  },
}
</script>

<style lang="scss" scoped>
.view {
  display: flex;
  align-items: flex-start;
  @include mobile {
    flex-direction: column;
    align-items: center;
  }
  .swiper-preview {
    @include desktopXL {
      height: uniWXL(417);
      width: uniWXL(88);
      margin-right: uniWXL(20);
    }
    @include desktop {
      height: uniW(417);
      width: uniW(88);
      margin-right: uniW(20);
    }
    @include mobile {
      display: none;
    }
    .swiper-slide {
      cursor: pointer;
      @include desktopXL {
        height: uniWXL(60);
        width: uniWXL(88);
      }
      @include desktop {
        height: uniW(60);
        width: uniW(88);
      }
    }
    .white {
      display: flex;
      justify-content: center;
      img {
        width: auto;
      }
      @include desktopXL {
        height: uniWXL(60);
        width: uniWXL(88);
      }
      @include desktop {
        height: uniW(60);
        width: uniW(88);
      }
    }
  }
  .swiper-relative {
    position: relative;
    &::after {
      @include mobile {
        position: absolute;
        content: '';
        width: 100%;
        height: uniWM(76);
        background: linear-gradient(0, #000000 0%, rgba(0, 0, 0, 0) 100%);
        opacity: 0.6;
        pointer-events: none;
        bottom: 0px;
        z-index: 1;
      }
    }
  }
  .swiper-view {
    @include desktopXL {
      height: uniWXL(417);
      width: uniWXL(630);
      margin-right: uniWXL(20);
      margin-bottom: uniWXL(20);
    }
    @include desktop {
      height: uniW(417);
      width: uniW(630);
      margin-right: uniW(20);
      margin-bottom: uniW(20);
    }
    @include mobile {
      height: uniWM(201);
      width: uniWM(305);
      border-radius: uniWM(20) uniWM(20) 0px 0px;
    }
    .swiper-slide,
    img {
      @include desktopXL {
        height: uniWXL(417);
        width: uniWXL(630);
      }
      @include desktop {
        height: uniW(417);
        width: uniW(630);
      }
      @include mobile {
        height: uniWM(201);
        width: uniWM(305);
      }
    }
    .swiper-slide {
      cursor: pointer;
    }
    .white {
      display: flex;
      justify-content: center;
      img {
        width: auto;
      }
      @include desktopXL {
        height: uniWXL(417);
        width: uniWXL(630);
      }
      @include desktop {
        height: uniW(417);
        width: uniW(630);
      }
      @include mobile {
        height: uniWM(201);
        width: uniWM(305);
      }
    }
  }
  .navigation-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    @include desktopXL {
      margin-right: uniWXL(20);
    }
    @include desktop {
      margin-right: uniW(20);
    }
    @include mobile {
      position: absolute;
      width: calc(100% - uniWM(40));
      margin: 0 uniWM(20);
      bottom: uniWM(20);
    }
    .pagination {
      @include desktopXL {
        left: uniWXL(44);
        bottom: uniWXL(6) !important;
      }
      @include desktop {
        left: uniW(44);
        bottom: uniW(6) !important;
      }
      @include mobile {
        display: none;
      }
    }
  }
  .navigation-button {
    @include desktopXL {
      width: uniWXL(24) !important;
    }
    @include desktop {
      width: uniW(24) !important;
    }
  }
}
</style>
