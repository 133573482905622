<template>
  <main>
    <Header />
    <SectionMain />
    <SectionMap />
    <SectionBooking />
    <SectionApartments />
    <SectionFaq />
    <SectionContacts />
    <Footer />
  </main>
</template>

<script>
import Footer from './components/Footer.vue'
import Header from './components/Header.vue'
import SectionApartments from './components/SectionApartments.vue'
import SectionBooking from './components/SectionBooking.vue'
import SectionContacts from './components/SectionContacts.vue'
import SectionFaq from './components/SectionFaq.vue'
import SectionMain from './components/SectionMain.vue'
import SectionMap from './components/SectionMap.vue'

export default {
  name: 'App',
  components: {
    Header,
    SectionMain,
    SectionMap,
    SectionBooking,
    SectionContacts,
    SectionApartments,
    SectionFaq,
    Footer,
  },
}
</script>

<style lang="scss" scoped></style>
