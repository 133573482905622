import { createStore } from 'vuex'

export default createStore({
  state: {
    summer: /^true$/.test(process.env.VUE_APP_IS_SUMMER),
    headerHeight: 104,
    menu: false,
    fullImages: {
      room: '',
      count: 1,
      initialSlide: 0,
      isShow: false,
    },
  },
  mutations: {
    changeSeason(state, value) {
      state.summer = value
    },
    setHeaderHeight(state, value) {
      state.headerHeight = value
    },
    changeMenu(state, value) {
      state.menu = value
    },
    setFullImagesParams(state, payload) {
      for (let key in payload) {
        state.fullImages[key] = payload[key]
      }
    },
  },
  actions: {},
  modules: {},
})
