<template>
  <section class="apartments" id="apartments">
    <div class="container">
      <h1 class="apartments-title">Наша <span>вилла:</span></h1>
      <div v-if="device !== 'mobile'">
        <div class="apartment">
          <ApartmentView :room="'room-8'" :count="24">
            <template #info>
              <div class="apartment-info">
                <h6>
                  Кухня-Гостиная с панорамным остеклением и вторым светом с
                  видом на сад и горы
                </h6>
                <p>
                  Современная кухня оснащена всем необходимым для приготовление
                  еды: посуда, столовые приборы, техника - включая духовку и
                  посудомойку.
                </p>
                <p>Дизайнерская мебель из натурального массива дерева.</p>
                <p>
                  Большой телевизор с Яндекс ТВ и удобный диван, который
                  раскладывается в спальное место.
                </p>
              </div>
            </template>
          </ApartmentView>
        </div>
        <div class="apartment">
          <ApartmentView :room="'room-12'" :count="8">
            <template #info>
              <div class="apartment-info">
                <h6>Мастер-спальня</h6>
                <p>
                  Мастер-спальня с панорамным остеклением, большой кроватью из
                  массива дерева, вместительным шкафом и собственным санузлом.
                </p>
              </div>
            </template>
          </ApartmentView>
        </div>
        <div class="apartment">
          <ApartmentView :room="'room-18'" :count="5">
            <template #info>
              <div class="apartment-info">
                <h6>Мастер-ванна</h6>
                <p>Мастер санузел с тропическим душем.</p>
              </div>
            </template>
          </ApartmentView>
        </div>
        <div class="apartment">
          <ApartmentView :room="'room-20'" :count="5">
            <template #info>
              <div class="apartment-info">
                <h6>Гостевая комната-1</h6>
                <p>
                  Гостевая комната с двумя удобными односпальными кроватями,
                  которые по желанию гостей можно объединить в одно большое
                  двухместное спальное место.
                </p>
              </div>
            </template>
          </ApartmentView>
        </div>
        <div class="apartment">
          <ApartmentView :room="'room-19'" :count="6">
            <template #info>
              <div class="apartment-info">
                <h6>Гостевая комната-2</h6>
                <p>
                  Гостевая комната с уютным диваном, который раскладывается в
                  двухместное спальное место.
                </p>
              </div>
            </template>
          </ApartmentView>
        </div>
        <div class="apartment">
          <ApartmentView :room="'room-21'" :count="5">
            <template #info>
              <div class="apartment-info">
                <h6>Гостевой санузел с полноценной ванной</h6>
                <p>Санузел с полноценной ванной и стиральной машиной.</p>
              </div>
            </template>
          </ApartmentView>
        </div>
        <div class="apartment">
          <ApartmentView :room="'room-22'" :count="14">
            <template #info>
              <div class="apartment-info">
                <h6>Сад и зона отдыха</h6>
                <p>
                  Большая панорамная площадка с зоной отдыха и зоной барбекю в
                  окружении гор и видом на море и закат. Дом утопает в зелени: в
                  собственном саду растут бананы, мандарины, инжир.
                </p>
              </div>
            </template>
          </ApartmentView>
        </div>
        <div class="apartment">
          <ApartmentView :room="'room-23'" :count="7">
            <template #info>
              <div class="apartment-info">
                <h6>Пляжи</h6>
                <p>
                  В 2,5 км от виллы вдоль береговой линии находится множество
                  пляжей. На пляжах крупная галька! На пляжах можно арендовать
                  лежаки, зонтики, качели под навесом. На ближайшем пляже
                  «Лазурный берег» есть детская площадка, кафе и прокат
                  спортивного оборудования. Над пляжем расположено кафе с
                  верандой и детской комнатой.
                </p>
                <p>
                  На этом же пляже находится серф-спот Hosta Rica — место, где
                  во время серф-прогноза можно встретить множество серферов, а
                  также проходит Чемпионат России по серфингу. На споте есть
                  серф школа, которая работает при благоприятном прогнозе.
                </p>
              </div>
            </template>
          </ApartmentView>
        </div>
        <div class="apartment">
          <ApartmentView :room="'room-24'" :count="7">
            <template #info>
              <div class="apartment-info">
                <h6>Интересное рядом</h6>
                <p>
                  Легендарная Тисо-самшитовая роща находится менее чем в 10
                  минутах езды на машине.
                </p>
                <p>
                  Каньон Белые скалы находится прямо над нами - дорога вверх на
                  машине займет до 20 минут.
                </p>
              </div>
            </template>
          </ApartmentView>
        </div>
      </div>
      <div v-else>
        <div class="apartment">
            <ApartmentView :room="'room-8'" :count="24">
              <template #info>
                <div class="apartment-info">
                  <h6>
                    Кухня-Гостиная с панорамным остеклением и вторым светом с
                    видом на сад и горы
                  </h6>
                  <p>
                    Современная кухня оснащена всем необходимым для
                    приготовление еды: посуда, столовые приборы, техника -
                    включая духовку и посудомойку.
                  </p>
                  <p>Дизайнерская мебель из натурального массива дерева.</p>
                  <p>
                    Большой телевизор с Яндекс ТВ и удобный диван, который
                    раскладывается в спальное место.
                  </p>
                </div>
              </template>
            </ApartmentView>
          </div>
          <div class="apartment">
            <ApartmentView :room="'room-12'" :count="8">
              <template #info>
                <div class="apartment-info">
                  <h6>Мастер-спальня</h6>
                  <p>
                    Мастер-спальня с панорамным остеклением, большой кроватью из
                    массива дерева, вместительным шкафом и собственным санузлом.
                  </p>
                </div>
              </template>
            </ApartmentView>
          </div>
          <div class="apartment">
            <ApartmentView :room="'room-18'" :count="5">
              <template #info>
                <div class="apartment-info">
                  <h6>Мастер-ванна</h6>
                  <p>Мастер санузел с тропическим душем.</p>
                </div>
              </template>
            </ApartmentView>
          </div>
          <div class="apartment">
            <ApartmentView :room="'room-20'" :count="5">
              <template #info>
                <div class="apartment-info">
                  <h6>Гостевая комната-1</h6>
                  <p>
                    Гостевая комната с двумя удобными односпальными кроватями,
                    которые по желанию гостей можно объединить в одно большое
                    двухместное спальное место.
                  </p>
                </div>
              </template>
            </ApartmentView>
          </div>
          <div class="apartment">
            <ApartmentView :room="'room-19'" :count="6">
              <template #info>
                <div class="apartment-info">
                  <h6>Гостевая комната-2</h6>
                  <p>
                    Гостевая комната с уютным диваном, который раскладывается в
                    двухместное спальное место.
                  </p>
                </div>
              </template>
            </ApartmentView>
          </div>
          <div class="apartment">
            <ApartmentView :room="'room-21'" :count="5">
              <template #info>
                <div class="apartment-info">
                  <h6>Гостевой санузел с полноценной ванной</h6>
                  <p>Санузел с полноценной ванной и стиральной машиной.</p>
                </div>
              </template>
            </ApartmentView>
          </div>
          <div class="apartment">
            <ApartmentView :room="'room-22'" :count="14">
              <template #info>
                <div class="apartment-info">
                  <h6>Сад и зона отдыха</h6>
                  <p>
                    Большая панорамная площадка с зоной отдыха и зоной барбекю в
                    окружении гор и видом на море и закат. Дом утопает в зелени:
                    в собственном саду растут бананы, мандарины, инжир.
                  </p>
                </div>
              </template>
            </ApartmentView>
          </div>
          <div class="apartment">
            <ApartmentView :room="'room-23'" :count="7">
              <template #info>
                <div class="apartment-info">
                  <h6>Пляжи</h6>
                  <p>
                    В 2,5 км от виллы вдоль береговой линии находится множество
                    пляжей. На пляжах крупная галька! На пляжах можно арендовать
                    лежаки, зонтики, качели под навесом. На ближайшем пляже
                    «Лазурный берег» есть детская площадка, кафе и прокат
                    спортивного оборудования. Над пляжем расположено кафе с
                    верандой и детской комнатой.
                  </p>
                  <p>
                    На этом же пляже находится серф-спот Hosta Rica — место, где
                    во время серф-прогноза можно встретить множество серферов, а
                    также проходит Чемпионат России по серфингу. На споте есть
                    серф школа, которая работает при благоприятном прогнозе.
                  </p>
                </div>
              </template>
            </ApartmentView>
          </div>
          <div class="apartment">
            <ApartmentView :room="'room-24'" :count="7">
              <template #info>
                <div class="apartment-info">
                  <h6>Интересное рядом</h6>
                  <p>
                    Легендарная Тисо-самшитовая роща находится менее чем в 10
                    минутах езды на машине.
                  </p>
                  <p>
                    Каньон Белые скалы находится прямо над нами - дорога вверх
                    на машине займет до 20 минут.
                  </p>
                </div>
              </template>
            </ApartmentView>
          </div>
      </div>
      <!-- <Swiper
        v-else
        :slides-offset-after="20"
        :slides-offset-before="20"
        :space-between="20"
        :slides-per-view="'auto'"
        :modules="modules"
        :pagination="{
          el: '.pagination-apartments',
          clickable: true,
        }"
        :loop="false"
        @swiper="onSwiper"
        class="swiper-apartments"
      >
        <SwiperSlide>
          <div class="apartment">
            <ApartmentView :room="'room-8'" :count="24">
              <template #info>
                <div class="apartment-info">
                  <h6>
                    Кухня-Гостиная с панорамным остеклением и вторым светом с
                    видом на сад и горы
                  </h6>
                  <p>
                    Современная кухня оснащена всем необходимым для
                    приготовление еды: посуда, столовые приборы, техника -
                    включая духовку и посудомойку.
                  </p>
                  <p>Дизайнерская мебель из натурального массива дерева.</p>
                  <p>
                    Большой телевизор с Яндекс ТВ и удобный диван, который
                    раскладывается в спальное место.
                  </p>
                </div>
              </template>
            </ApartmentView>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div class="apartment">
            <ApartmentView :room="'room-12'" :count="8">
              <template #info>
                <div class="apartment-info">
                  <h6>Мастер-спальня</h6>
                  <p>
                    Мастер-спальня с панорамным остеклением, большой кроватью из
                    массива дерева, вместительным шкафом и собственным санузлом.
                  </p>
                </div>
              </template>
            </ApartmentView>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div class="apartment">
            <ApartmentView :room="'room-18'" :count="5">
              <template #info>
                <div class="apartment-info">
                  <h6>Мастер-ванна</h6>
                  <p>Мастер санузел с тропическим душем.</p>
                </div>
              </template>
            </ApartmentView>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div class="apartment">
            <ApartmentView :room="'room-20'" :count="5">
              <template #info>
                <div class="apartment-info">
                  <h6>Гостевая комната-1</h6>
                  <p>
                    Гостевая комната с двумя удобными односпальными кроватями,
                    которые по желанию гостей можно объединить в одно большое
                    двухместное спальное место.
                  </p>
                </div>
              </template>
            </ApartmentView>
          </div></SwiperSlide
        ><SwiperSlide>
          <div class="apartment">
            <ApartmentView :room="'room-19'" :count="6">
              <template #info>
                <div class="apartment-info">
                  <h6>Гостевая комната-2</h6>
                  <p>
                    Гостевая комната с уютным диваном, который раскладывается в
                    двухместное спальное место.
                  </p>
                </div>
              </template>
            </ApartmentView>
          </div></SwiperSlide
        ><SwiperSlide>
          <div class="apartment">
            <ApartmentView :room="'room-21'" :count="5">
              <template #info>
                <div class="apartment-info">
                  <h6>Гостевой санузел с полноценной ванной</h6>
                  <p>Санузел с полноценной ванной и стиральной машиной.</p>
                </div>
              </template>
            </ApartmentView>
          </div></SwiperSlide
        ><SwiperSlide>
          <div class="apartment">
            <ApartmentView :room="'room-22'" :count="14">
              <template #info>
                <div class="apartment-info">
                  <h6>Сад и зона отдыха</h6>
                  <p>
                    Большая панорамная площадка с зоной отдыха и зоной барбекю в
                    окружении гор и видом на море и закат. Дом утопает в зелени:
                    в собственном саду растут бананы, мандарины, инжир.
                  </p>
                </div>
              </template>
            </ApartmentView>
          </div></SwiperSlide
        ><SwiperSlide>
          <div class="apartment">
            <ApartmentView :room="'room-23'" :count="7">
              <template #info>
                <div class="apartment-info">
                  <h6>Пляжи</h6>
                  <p>
                    В 2,5 км от виллы вдоль береговой линии находится множество
                    пляжей. На пляжах крупная галька! На пляжах можно арендовать
                    лежаки, зонтики, качели под навесом. На ближайшем пляже
                    «Лазурный берег» есть детская площадка, кафе и прокат
                    спортивного оборудования. Над пляжем расположено кафе с
                    верандой и детской комнатой.
                  </p>
                  <p>
                    На этом же пляже находится серф-спот Hosta Rica — место, где
                    во время серф-прогноза можно встретить множество серферов, а
                    также проходит Чемпионат России по серфингу. На споте есть
                    серф школа, которая работает при благоприятном прогнозе.
                  </p>
                </div>
              </template>
            </ApartmentView>
          </div></SwiperSlide
        ><SwiperSlide>
          <div class="apartment">
            <ApartmentView :room="'room-24'" :count="7">
              <template #info>
                <div class="apartment-info">
                  <h6>Интересное рядом</h6>
                  <p>
                    Легендарная Тисо-самшитовая роща находится менее чем в 10
                    минутах езды на машине.
                  </p>
                  <p>
                    Каньон Белые скалы находится прямо над нами - дорога вверх
                    на машине займет до 20 минут.
                  </p>
                </div>
              </template>
            </ApartmentView>
          </div>
        </SwiperSlide>
      </Swiper>
      <div class="pagination-apartments orange" /> -->
    </div>
    <FullView
      v-if="fullImages.isShow"
      :initialSlide="fullImages.initialSlide"
      :count="fullImages.count"
      :room="fullImages.room"
      @close="setFullImagesParams({ isShow: false })"
    />
  </section>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { ref } from 'vue'
// import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'
import 'swiper/swiper.min.css'
import 'swiper/modules/navigation/navigation.scss'
import { Pagination } from 'swiper'

import ApartmentView from './ApartmentView.vue'
import FullView from './FullView.vue'
export default {
  name: 'SectionApartments',
  components: {
    ApartmentView,
    // Swiper,
    // SwiperSlide,
    FullView,
  },
  computed: {
    ...mapState(['fullImages']),
  },
  methods: {
    ...mapMutations(['setFullImagesParams']),
  },
  setup() {
    const swiper = ref(null)

    const onSwiper = (sw) => {
      swiper.value = sw
    }

    return {
      onSwiper,
      modules: [Pagination],
    }
  },
}
</script>

<style lang="scss" scoped>
.apartment {
  @include mobile {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}

.apartments {
  background: $main-grey;
  @include desktopXL {
    padding: uniWXL(60) 0;
    margin-bottom: uniWXL(100);
  }
  @include desktop {
    padding: uniW(60) 0;
    margin-bottom: uniW(100);
  }
  @include mobile {
    padding: uniWM(40) 0 uniWM(65) 0;
  }
  &-title {
    color: $white;
    @include desktopXL {
      font-size: uniWXL(70);
      line-height: uniWXL(93);
      margin-bottom: uniWXL(60);
    }
    @include desktop {
      font-size: uniW(70);
      line-height: uniW(93);
      margin-bottom: uniW(60);
    }
    @include mobile {
      font-size: uniWM(40);
      line-height: uniWM(40);
      margin-bottom: uniWM(40);
    }
    span {
      color: $orange;
      font-style: italic;
      @include mobile {
        transform: translateX(uniWM(24));
        display: block;
        width: fit-content;
      }
    }
  }
  .apartment {
    @include desktopXL {
      margin-bottom: uniWXL(100);
    }
    @include desktop {
      margin-bottom: uniW(100);
    }
    &:last-child {
      margin-bottom: 0;
    }
    &-info {
      @include desktopXL {
        max-width: uniWXL(522);
      }
      @include desktop {
        max-width: uniW(522);
      }
      @include mobile {
        background: white;
        width: uniWM(305);
        // height: uniWM(579);
        height: auto;
        padding: uniWM(20);
        margin-top: -1px;
      }
      h6 {
        color: $orange;
        @include desktopXL {
          font-size: uniWXL(16);
          line-height: uniWXL(22);
          margin-bottom: uniWXL(40);
        }
        @include desktop {
          font-size: uniW(16);
          line-height: uniW(22);
          margin-bottom: uniW(40);
        }
        @include mobile {
          font-size: uniWM(16);
          line-height: uniWM(22);
          margin-bottom: uniWM(15);
        }
      }
      p {
        color: $white;
        @include desktopXL {
          font-size: uniWXL(16);
          line-height: uniWXL(22);
          margin-bottom: uniWXL(20);
        }
        @include desktop {
          font-size: uniW(16);
          line-height: uniW(22);
          margin-bottom: uniW(20);
        }
        @include mobile {
          color: $main-grey;
          font-size: uniWM(16);
          line-height: uniWM(22);
          margin-bottom: uniWM(20);
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
.swiper-apartments {
  @include mobile {
    width: 100%;
    max-width: 100vw;
    margin: 0 uniWM(-20);
  }
  .swiper-slide {
    width: auto;
    min-width: 100%;
  }
}
.pagination-apartments {
  left: 50%;
  transform: translateX(-50%);
  bottom: uniWM(-40);
  width: uniWM(230);
}
</style>
